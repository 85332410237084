@import '../../styles/theme';

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 50px 0;
  
  @media screen and (max-width: 768px) {
    gap: 20px;
    margin: 30px 0;
  }
}

.skillSection {
  width: calc(50% - 30px);
  margin-bottom: 30px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 1.3em;
    font-weight: 500;
    color: $color-secondary;
    margin-bottom: 25px;
    position: relative;
    display: inline-block;
  }
}

.skillItems {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skillItem {
  display: inline-block;
  padding: 8px 15px;
  border-radius: 4px;
  background-color: rgba($color-primary, 0.08);
  color: $color-secondary;
  font-size: 0.9em;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  
  &:hover {
    background-color: rgba($color-primary, 0.12);
    transform: translateY(-2px);
    border-color: rgba($color-primary, 0.2);
  }
}
