@import '../../styles/theme';

.navbarContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // box-shadow: 2px 2px 7px 0px rgb(171 171 171 / 20%);
  background-color: $color-background;
  z-index: 100;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  & > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  & .navbar {
    display: flex;
    justify-content: space-around;
    width: fit-content;
    font-size: 1.2em;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      font-size: 1em;
    }
    & a {
      position: relative;
      text-decoration: none;
      color: $color-secondary;
      margin-right: 2rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        color: $color-primary;
        &::after {
          width: 100%;
        }
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -30px;
        width: 0;
        height: 3px;
        background-color: $color-primary;
        transition: width 0.15s;
      }
    }
  }
}
