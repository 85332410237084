@import '../../styles/theme';

.contactLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 80px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
  }
}

.contactItem {
  a {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    background-color: rgba($color-primary, 0.06);
    border-radius: 8px;
    color: $color-secondary;
    text-decoration: none;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    
    &:hover {
      background-color: rgba($color-primary, 0.1);
      color: $color-primary;
      transform: translateY(-3px);
      border-color: rgba($color-primary, 0.2);
      
      .icon {
        fill: $color-primary;
      }
    }
    
    span {
      margin-left: 10px;
      font-weight: 500;
      font-size: 1.1em;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  fill: $color-secondary;
  transition: fill 0.2s ease;
}
