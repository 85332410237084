@import '../../styles/theme';

.projectCard {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 60px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05), 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  & .projectImage {
    width: 80%;
    position: absolute !important;
    top: 0;
    left: 0;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  & .projectBackground {
    position: absolute;
    top: -1px;
    right: -1px;
    height: 110%;
    width: 83%;
    background: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMxNiIgdmlld0JveD0iMCAwIDQ0NiAzMTYiIHdpZHRoPSI0NDYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09IiI+PHBhdGggZD0ibTI3NC41NDkwNTQgMzE2aDE3MS40NTA5NDZ2LTMxNmgtMzY2LjIyMTgzMDNsLTI3LjgxNjg1NjMgMTExLjMwNzAxIDUxLjEzNjc5NDYgMjA0LjY5Mjk5eiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Im0yNC4xNTEwOTI1IDBoNTUuNjk3ODE1bC0yNy44ODg4ODQyIDExMS4zMjA5ODR6IiBmaWxsPSIjYzFkZGZmIiBvcGFjaXR5PSIuNTU2ODM5Ii8+PHBhdGggZD0ibTUxLjk2MDMxMTkgMTExLjMxODAyNCA1MS4xNDkyMjcxIDIwNC42ODE5NzZoLTEwMi4zMTE1NTcxNHoiIGZpbGw9IiM5Mzg3ZTQiIG9wYWNpdHk9Ii41MjY2MDgiLz48L2c+PC9zdmc+);
    background-size: cover;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  & .projectContent {
    padding: 60px;
    width: 50%;
    z-index: 2;
    font-weight: 500;
    @media screen and (max-width: 1023px) {
      width: 100%;
      padding: 35px;
      box-sizing: border-box;
    }
    & a {
      font-weight: 500;
    }
    & h3 {
      font-weight: 500;
      font-size: 1.8em;
      line-height: 38px;
      color: $color-secondary;
      margin-top: 0;
      margin-bottom: 35px;
    }
    & p {
      margin-bottom: 30px;
      font-size: 0.95rem;
      line-height: 27px;
      color: $color-secondary;
    }
    & .projectSkills {
      display: flex;
      flex-wrap: wrap;
      & .projectSkill {
        border-radius: 4px;
        border: 2px solid #92eac0;
        padding: 8px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 0.85em;
        color: $color-secondary;
      }
    }
    & .projectLinks {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      & .projectLink {
        margin-right: 20px;
        margin-top: 20px;
        white-space: nowrap;
        display: inline-block;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        border-radius: 4px;
        letter-spacing: 0.025em;
        text-decoration: none;
        transition: all 0.15s ease;
        font-size: 0.9em;
        font-weight: 600;
        box-sizing: border-box;
        background: $color-primary;
        color: #fff;
        &.secondary {
          background: #fff;
          color: $color-primary;
        }
      }
    }
  }
}
