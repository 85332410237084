@import '../../styles/theme';

.terminal {
  & .terminalHeader {
    height: 28px;
    background-color: #e4e3e5;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & .headerButton {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 9px;
    }
    & .red {
      background-color: #f96256;
      border: 1px solid #f65549;
    }
    .yellow {
      background-color: #fdbc3d;
      border: 1px solid #ffb524;
    }
    .green {
      background-color: #33c948;
      border: 1px solid #2dbb41;
    }
  }
  & .terminalWindow {
    background-color: $color-semidark;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 50px 100px rgb(50 50 93 / 15%), 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 12%);
    height: 560px;
    overflow: auto;
    @media screen and (max-width: 768px) {
      height: 450px;
    }
    & a {
      color: #35feff;
    }
    & .statement {
      margin-bottom: 25px;
      margin-left: 35px;
      margin-right: 35px;
      line-height: 24px;
      font-size: 1.15rem;
      font-weight: 500;
      letter-spacing: 1px;
      font-family: 'Inconsolata', monospace;
      & input {
        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        color: #f7f7f7;
        width: 80%;
      }
      &:first-child {
        padding-top: 35px;
      }
      &:last-child {
        padding-bottom: 60px;
      }
      & .inputStatement {
        color: #f7f7f7;
        &::before {
          content: '>\A0';
        }
      }
      & .returnStatement {
        color: #e7d184;
      }
    }
  }
}
