@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500&family=Raleway:wght@100;200;300;400;500&display=swap');
@import './theme';
@import './css-reset';

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'pnum';
  font-variant-numeric: proportional-nums;
  line-height: 1;
}

main > .container-lg {
  margin-top: 270px;
  @media screen and (max-width: 768px) {
    margin-top: 140px;
  }
}

a {
  text-decoration: none;
  color: $color-primary;
  transition: 0.1s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #e4e3e5;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container-lg {
  max-width: 850px;
  margin: 0 auto;
  padding: 0 40px;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

.section-title {
  color: $color-secondary;
  font-weight: 500;
  font-size: 1.5em;
  text-align: center;
  padding-top: 50px;
  margin-top: 50px;
  margin-bottom: 80px;
  &::after {
    content: ' ';
    border: 2px solid $color-primary;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    width: 50px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.about-container {
  margin-top: 20vh;
  margin: 0 auto;
}

.projects-container {
  margin: 0 auto;
}

.contact-container {
  padding-bottom: 200px;
  @media screen and (max-width: 768px) {
    padding-bottom: 80px;
  }
}

.footer {
  margin: 50px auto;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
  padding: 20px 0;
  color: $color-secondary;
  border-top: 1px solid rgba($color-secondary, 0.1);
  
  @media screen and (max-width: 768px) {
    margin: 30px auto;
    padding: 15px 0;
  }
}
