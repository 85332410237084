@import '../../styles/theme';

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.heroContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  & a {
    font-weight: 600;
  }
  & .heroTitle {
    font-family: 'Inconsolata', monospace;
    font-weight: 500;
    font-size: 5rem;
    letter-spacing: 1px;
    color: $color-primary;
    @media screen and (max-width: 768px) {
      font-size: 3rem;
    }
    &:before {
      content: '>\A0';
      font: inherit;
      @media screen and (max-width: 768px) {
        content: '';
      }
    }
    & span {
      font: inherit;
      margin-left: 7px;
      width: 15px;
      height: 1.1em;
      background-color: lighten($color: $color-primary, $amount: 5%);
      display: inline-block;
      animation: 1.5s fade infinite;
      animation-delay: 3s;
    }
  }
  & .heroDescription {
    font-size: 1.3rem;
    line-height: 40px;
    color: $color-secondary;
    margin: 80px 0;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      line-height: 30px;
      max-width: 100%;
      margin: 50px 0;
    }
  }
}
