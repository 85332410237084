@import '../../styles/theme';

.journey {
  margin-top: 130px;
  margin-bottom: 80px;
  @media screen and (max-width: 910px) {
    margin-top: 80px;
    margin-bottom: 0;
    
  }
}
