.topScroll {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  @media screen and (max-width: 768px) {
    bottom: 0.5rem;
    right: 0.5rem;
  }
  width: 40px;
  height: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  filter: invert(36%) sepia(12%) saturate(656%) hue-rotate(166deg) brightness(100%) contrast(86%);
  display: none;
}
